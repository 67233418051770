<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="ml-1">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">Home</li>
                <li class="breadcrumb-item active" aria-current="page">
                  {{ $t("country.title") }}
                </li>
              </ol>
            </nav>
          </div>
          <div class="row ml-3 mb-3">
            <h6 class="ssp-24">{{ $t("country.title") }}</h6>
            <button class="btn btn-success btn-add ml-auto mr-4" @click="onAdd">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="20"
                height="20"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                  fill="rgba(255,255,255,1)"
                />
              </svg>
              {{ $t("country.add") }}
            </button>
          </div>
          <div class="row table-utilities">
            <div class="row ml-2 w-100">
              <div class="col-md-10 search-section">
                <div class="input-group">
                  <div class="input-group-prepend bg-white">
                    <span class="input-group-text" id="basic-addon1">
                      <div class="font-12">
                        <i class="ri-search-line ri-lg ri-sub"></i>
                      </div>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control search-input"
                    :placeholder="$t('country.search')"
                    v-model="filterInput"
                  />
                </div>
              </div>
              <div class="col-md-2 pl-0">
                <button
                  class="btn table-button ssp-14-700 export-button h-100 w-100 d-flex align-items-center"
                  @click="handleExport"
                  :disabled="isLoading"
                >
                  <div class="font-12">
                    <div
                      v-if="isLoading"
                      class="spinner-border spinner-border-sm text-success mr-1"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                    <i v-else class="ri-download-line ri-lg ri-mid mr-1"></i>
                  </div>
                  {{ $t("global.export") }}
                </button>
              </div>
            </div>
          </div>
          <!-- <div class="row table-utilities">
            <div class="col-md-10 search-section">
              <div class="input-group">
                <div class="input-group-prepend bg-white">
                  <span class="input-group-text" id="basic-addon1"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="15"
                      height="15"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M11 2c4.968 0 9 4.032 9 9s-4.032 9-9 9-9-4.032-9-9 4.032-9 9-9zm0 16c3.867 0 7-3.133 7-7 0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7zm8.485.071l2.829 2.828-1.415 1.415-2.828-2.829 1.414-1.414z"
                        fill="rgba(117,117,117,1)"
                      />
                      />
                    </svg></span
                  >
                </div>
                <input
                  type="text"
                  class="form-control search-input"
                  placeholder="Cari data country disini"
                  v-model="filterInput"
                />
              </div>
            </div>
            <div
              class="col-md-1 pl-0"
              style="width: 16.6%; flex: 0 0 16.6%;max-width: 16.6%;"
            >
              <div
                class="btn table-button ssp-14-700 export-button h-100 d-flex align-items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  class="mr-1"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M3 19h18v2H3v-2zm10-5.828L19.071 7.1l1.414 1.414L12 17 3.515 8.515 4.929 7.1 11 13.17V2h2v11.172z"
                    fill="rgba(40,164,74,1)"
                  />
                </svg>
                Export
              </div>
            </div>
          </div> -->
          <div class="table-borderless mx-3">
            <b-table
              hover
              :items="dataCountry"
              :fields="[
                {
                  label: '#',
                  key: 'index'
                },
                {
                  label: $t('country.headers[1]'),
                  key: 'country',
                  sortable: true
                },
                {
                  label: $t('country.headers[2]'),
                  key: 'actions'
                }
              ]"
              :filter="filterInput"
              sort-icon-left
              show-empty
              small
              responsive="true"
              class="simple-master-table"
            >
              <template #cell(index)="data">
                <ul class="list-inline d-inline-block" title="Farmer Name">
                  <li class="list-inline-item">
                    {{ ++data.index }}
                  </li>
                  <br />
                </ul>
              </template>

              <template #cell(country)="data">
                <ul
                  class="list-inline d-inline-block mw-100"
                  style="width: max-content"
                  title="Farmer Name"
                >
                  <li class="list-inline-item">
                    {{ data.item.country }}
                  </li>
                  <br />
                </ul>
              </template>

              <template v-slot:cell(actions)="{ item }">
                <ul
                  style="width: max-content"
                  class="list-inline d-inline-block mw-100"
                >
                  <div class="action-wrapper font-12">
                    <i
                      class="ri-edit-fill ri-lg ri-mid action-btn"
                      @click="onEdit(item)"
                    ></i>
                    <i
                      class="ri-delete-back-2-fill ri-lg ri-mid action-btn"
                      @click="onDelete(item.id)"
                    ></i>
                  </div>
                </ul>
              </template>
            </b-table>

            <div class="row align-items-center table-footer">
              <div class="col-sm-6 ssp-14-700">
                <!-- 1 - 6 of {{ this.totalData }} -->
                {{ $t("global.total_data") }} {{ this.totalData }}
              </div>
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-7 align-self-center page-text">
                    {{ $t("global.page_on") }}
                  </div>
                  <div class="col float-right">
                    <div class="row">
                      <select
                        class="form-control pagination-pg"
                        v-model="currPage"
                      >
                        <option
                          v-for="index in totalPages"
                          :key="index.id"
                          :value="index"
                        >
                          {{ index++ }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col page-arrow ssp-14-700 ml-2">
                    <div class="row float-right">
                      <div
                        @click="redcPage"
                        class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                      >
                        <div class="font-12" :class="{ orange: currPage > 1 }">
                          <i class="ri-arrow-left-line ri-lg ri-sub"></i>
                        </div>
                      </div>
                      <div
                        @click="addPage"
                        class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                      >
                        <div
                          class="font-12"
                          :class="{ orange: currPage < totalPages }"
                        >
                          <i class="ri-arrow-right-line ri-lg ri-sub"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
    <b-modal id="modal-country" centered hide-footer>
      <template #modal-header="{}">
        <!-- Emulate built in modal header close button action -->
        <h5 class="ssp-24 text-black">Tambah Data Country</h5>
      </template>

      <template #default="{ hide }">
        <!-- <p class="">Modal Body with button</p> -->
        <form @submit="onSubmit">
          <div class="form-group">
            <label for="nama-country" class="ssp-16 text-black">
              {{ $t("country.label.name") }} <span class="text-red">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              :class="{ 'invalid-form': $v.$error }"
              id="nama-country"
              v-model="$v.formTambah.nama_country.$model"
              :placeholder="$t('country.placeholder.name')"
            />
            <div class="text-sm text-red" v-if="$v.$error">
              <div v-if="!$v.required">{{ $t("feedback.required") }}</div>
            </div>
          </div>
          <button type="submit" class="btn btn-primary ssp-14 w-100 my-3 py-2">
            {{ $t("global.save") }}
          </button>
        </form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { Card } from "@/components/index";

import "../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";
import { required } from "vuelidate/lib/validators";
import {
  Q_LIST_COUNTRY,
  M_ADD_COUNTRY,
  M_DEL_COUNTRY,
  M_EDIT_COUNTRY
} from "@/graphql/super_admin";

export default {
  components: {
    Card
  },
  data() {
    return {
      isLoading: false,
      fieldsCountry: [
        {
          label: "#",
          key: "index"
        },
        {
          label: "Country Name",
          key: "country",
          sortable: true
        },
        {
          label: "Action",
          key: "actions"
        }
      ],
      dataCountry: [],
      filterInput: null,
      modalTitle: "",
      isEdit: false,

      totalPages: null,
      currPage: 1,
      perPage: 10,
      totalData: null,

      formTambah: {
        id: "",
        nama_country: ""
      }
    };
  },
  validations: {
    formTambah: {
      nama_country: { required }
    }
  },
  apollo: {
    listCountry: {
      query: () => Q_LIST_COUNTRY,
      variables() {
        return {
          page: 1,
          limit: 999
        };
      },
      result({ data }) {
        this.dataCountry = data.listCountry.countrys;
        this.totalPages = data.listCountry.totalPage;
        this.totalData = data.listCountry.total;
      },
      error(err) {
        this.catchError(err);
      }
    }
  },
  methods: {
    onAdd() {
      this.modalTitle = this.$t("country.add");
      this.isEdit = false;
      this.resetForm();
      this.$bvModal.show("modal-country");
    },
    setPage() {
      this.currPage = 1;
    },
    addPage() {
      if (this.currPage < this.totalPages) {
        this.currPage++;
      }
    },
    redcPage() {
      if (this.currPage > 1) {
        this.currPage--;
      }
    },
    closeModal() {
      this.$bvModal.hide("modal-country");
    },
    resetForm() {
      this.formTambah.id = "";
      this.formTambah.nama_country = "";
      this.$v.$reset();
    },
    onEdit(data) {
      this.modalTitle = this.$t("country.edit");
      this.isEdit = true;
      this.resetForm();
      // console.log(data);

      this.formTambah.id = data.id;
      this.formTambah.nama_country = data.country;

      this.$bvModal.show("modal-country");
    },
    onSubmit(ev) {
      ev.preventDefault();
      this.$v.$touch();
      if (!this.$v.$error) {
        if (this.isEdit) {
          return new Promise((resolve, reject) => {
            const editCategory = this.$apollo.mutate({
              mutation: M_EDIT_COUNTRY,
              variables: {
                id: this.formTambah.id,
                country: this.formTambah.nama_country
              }
            });
            if (resolve) {
              resolve(editCategory);
            } else {
              reject(editCategory);
            }
          })
            .then(response => {
              if (response) {
                this.toastAlert(this.$t("alert.edit"));
                this.closeModal();
                this.$apollo.queries.listCountry.refetch();
              }
            })
            .catch(err => {
              this.$swal.fire({
                icon: "error",
                title: "Error!",
                text: err.message,
                width: 400,
                confirmButtonText: "OK"
              });
            });
        } else {
          return new Promise((resolve, reject) => {
            const addCountry = this.$apollo.mutate({
              mutation: M_ADD_COUNTRY,
              variables: {
                country: this.formTambah.nama_country
              }
            });
            if (resolve) {
              resolve(addCountry);
            } else {
              reject(addCountry);
            }
          })
            .then(response => {
              if (response) {
                this.toastAlert(this.$t("alert.save"));
                this.closeModal();
                this.$apollo.queries.listCountry.refetch();
              }
            })
            .catch(err => {
              this.errorAlert(err);
            });
        }
      }
    },
    onDelete(id) {
      this.deleteConfirm().then(async result => {
        if (result.isConfirmed) {
          return new Promise((resolve, reject) => {
            const delCountry = this.$apollo.mutate({
              mutation: M_DEL_COUNTRY,
              variables: {
                id: id
              }
            });
            if (resolve) {
              resolve(delCountry);
            } else {
              reject(delCountry);
            }
          })
            .then(response => {
              if (response) {
                this.toastAlert(this.$t("alert.delete"));
                this.$apollo.queries.listCountry.refetch();
              }
            })
            .catch(err => {
              this.errorAlert(err);
            });
        }
      });
    },
    async handleExport() {
      this.isLoading = true;
      try {
        const result = await this.$apollo.query({
          query: Q_LIST_COUNTRY,
          variables: {
            page: 1,
            limit: 999
          }
        });
        const list = result.data.listCountry.countrys;
        import("@/plugins/export-excel").then(excel => {
          const tHeader = ["#", "Country"];
          const filterVal = ["index", "country"];
          setTimeout(() => {
            list.map((a, b) => {
              a.index = b + 1;
              return a;
            });
            const data = this.formatJson(filterVal, list);
            excel.export_json_to_excel({
              header: tHeader,
              data,
              filename: this.$route.name,
              autoWidth: true,
              bookType: "xlsx"
            });
          }, 1500);
          this.limit = 10;
        });
      } catch (e) {
        this.errorAlert(e);
      }
      this.isLoading = false;
    }
  },
  mounted() {}
};
</script>

<style></style>
